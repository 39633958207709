<template>
  <div class="mb-100">
    <div class="divider-full"></div>
    <div class="container-fluid">
      <div class="container-standar">
        <div class="row">
          <div class="col-md-12 pt-2  mt-4 pb-3">
            <a href="#" class="text-primary font-weight-6 font-16">Home /</a>
            <a href="#" class="text-primary font-weight-6 font-16">List Tour /</a>
            <a href="#" class="text-dark font-weight-6 font-16"> {{ dataContent.nama }}</a>
          </div>
        </div>
        <!-- <slick ref="slick" class="slickHome slickDetailUp" :options="slickOptions">
          <div class="slick-content-detail" :key="index" v-for="(items, index) in slideData" >
            <img  :src="items.file" alt />
          </div>
           
        </slick> -->
      </div>
    </div>
    <div class="mt-6 container-standar pl-2 pr-2">
      <div class="row">
        <div class="col-md-8 w-res-tab-small-flex-100">
          <div class="img_haji">
            <slick  ref="slick"   class="slickHome slickDetailUp" :options="slickOptions">
              <div class="slick-content-detail" :key="index" v-for="(items, index) in slideData" >
                <img  :src="items.file" :alt="items.slug" />
              </div>
              
            </slick>
          </div>
          <div class="width-100 shadow card pl-10 pr-10 pb-10">
            <h1
              class="d-block font-weight-7  mt-4 font-24 text-primary mb-4"
            >{{ dataContent.nama }}</h1>
            <div class="mt-10 clear" v-html="dataContent.deskripsi"> 
            </div>
          </div>
          <div class="width-100 mt-4 mb-5">
            <div role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    href="#"
                    v-b-toggle.accordion-1
                    variant="default"
                    class="text-primary w-100 text-left font-weight-6 font-18"
                  >
                    Itineraries
                    <i class="float-right fa fa-angle-down"></i>
                  </b-button>
                </b-card-header>
                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                  <b-card-body class="text-left">
                   
                      <div class="clear" v-html="dataContent.itinerary"></div>
                    </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </div>
        <div class="col-md-4 w-res-tab-small-flex-100">
          <div v-show="!LoginCheck">
            <div class="card shadow overflow-hidden">
              <span class="d-block text-center w-100 font-18 text-white pad-5 font-weight-6 bg-danger" v-if="$store.state.userdata == null">
                Harap Login Terlebih Dahulu
              </span>
              <span
                class="text-warning border-bottom-2 bc-warning pt-2 pb-2 font-20 text-center d-block w-100 font-weight-6"
              >Daftar Sekarang</span>
              <div class="width-100 pl-4 pr-4 pt-3 pb-3 d-inline-block border-bottom-1 bc-warning">
                <span
                  class="text-primary d-block width-100 font-16 mb-20 font-weight-6"
                >{{ dataContent.nama }}</span>                  
                <span class="float-left width-35 text-dark font-14 mb-10 font-weight-6">Price/Pax</span>
                <span
                  class="float-left width-65 text-right text-danger font-16 mb-10 font-weight-6"
                >IDR. {{ dataContent.harga | numeralFormat('0,0[.]00') }}</span>
              </div>
              <div class="width-100 pl-4 pr-4 pt-1 border-bottom-1 bc-warning pb-3 d-inline-block">
                  <div class="form-group mt-1">
                    <label for="name" class="text-primary font-12 font-weight-6">Nama Lengkap</label>
                    <input
                      type="text"
                      placeholder="Nama Lengkap"
                      v-model="name_User"
                         name="Name"
                        v-validate="'required'"
                      class="input-same-select pos-relative"
                    />
                       <span class="eror text-danger font-10">{{errors.first('Name')}}</span>
                  </div>
                  
                  <div class="form-group">
                    <label for="name" class="text-primary font-12 font-weight-6">Nomor Hp/Wa</label>
                    <input
                      type="number"
                      placeholder="Nomor Hp/Wa"
                      v-model="phone"
                          name="phone_number"
                        v-validate="'required'"
                      class="input-same-select pos-relative"
                    />
                     <span class="eror text-danger font-10">{{errors.first('phone_number')}}</span>
                  </div>
                  <div class="form-group">
                    <label
                      for="anyDes"
                      class="text-primary font-12 font-weight-6"
                    >Tanggal Keberangkatan</label>
                    <div class="icon-date">
                      <flat-pickr
                        v-model="tourDate"
                        :config="config"
                        class="input-same-select"
                        placeholder="Select date"
                        name="date_tour"
                        v-validate="'required'"
                      ></flat-pickr>
                      <span class="eror text-danger font-10">{{errors.first('date_tour')}}</span>
                    </div>
                  </div>

                  <div class="group-field">
                    <label for="anyDes" class="text-primary font-12 font-weight-6">Jumlah Pendaftar</label>
                    <div class="input-group">
                      <input
                        type="number"
                        name="JumlahVisa"
                        class="form-control"
                        v-model="paxTour"
                        placeholder="Input Jumlah Pax"
                        v-validate="'min_value:1'"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon2">Pax</span>
                      </div>
                    </div>
                    <span class="eror text-danger font-10">{{errors.first('JumlahVisa')}}</span>
                  </div>
              </div>
                
              <div class="width-100 pl-4 pr-4 pt-3 border-bottom-1 bc-warning pb-3 d-inline-block">
                <span class="float-left width-35 text-primary font-18 mb-10 font-weight-6">
                Total DP
                </span>
                <span
                  class="float-left width-65 text-right text-danger font-18 mb-10 font-weight-6"
                >IDR. {{ paxCalculate(dataContent.dp_harga) | numeralFormat('0,0[.]00') }}</span>
                <span class="float-left width-35 text-primary font-18 mb-10 font-weight-6">
                Total Harga
                </span>
                <span
                  class="float-left width-65 text-right text-danger font-18 mb-10 font-weight-6"
                >IDR. {{ paxCalculate(dataContent.harga) | numeralFormat('0,0[.]00') }}</span>
              </div>
              <div class="form-group pt-4 pb-4 pad-10 d-inline-block">
                <span
                      @click.prevent="Validation()"
                      class="d-block w-70 bg-warning text-center pad-15 border-radius-5 cursor-pointer text-white"
                    >Selanjutnya</span>
              </div>
            </div>

            <div class="row">
                <div class="col-lg-12 pt-2 mt-4 pb-3">
                  <div class="card shadow text-left pad-10">
                    <span
                      class="d-inline-block bor-bot-1 width-100 pt-2 pb-2 font-18 font-weight-7 text-primary"
                    >Paket Tour Lainnya</span>
                    <div class="cursor-pointer" :key="index" v-for="(items, index) in dataProduk.slice(0, 4)">
                      <div class="suggest-img" @click.prevent="changePage(items)">
                        <div class="cont-img-100">
                          <template v-if="items.images == 0">
                            <img src="@/assets/images/background/tour-1.jpg" :alt="items.slug">
                          </template>
                          <template v-else >
                            <div>
                              <img v-bind:src="chesen(items)" class="width-150" :alt="items.slug" />
                            </div>
                          </template>
                        </div>
                        <span class="title font-14 font-weight-6">
                          {{items.nama}}
                          <br />
                          <span class="text-grey font-weight-6 mt-2 font-12 d-inline-block">
                            <span class="text-primary font-weight-6">Real Travel</span>
                          </span>
                        </span>
                      </div>
                    </div> 
                  </div>
                </div>
              </div>

          </div>
          <div v-show = LoginCheck>
             <div class="card shadow text-left pad-10">
                <span
                  class="d-inline-block bor-bot-1 width-100 pt-2 pb-2 font-18 font-weight-7 text-primary"
                >Review Pemesanan</span>
                <div class="form-group d-inline-block mt-4">
                  <span class="float-left">Nama Pemesan</span>
                  <span class="float-right font-weight-6 text-primary">{{ name_User }}</span>
                </div> 
                <div class="form-group d-inline-block">
                  <span class="float-left">No Telp/HP</span>
                  <span class="float-right font-weight-6 text-primary">{{ phone }}</span>
                </div> 
                <div class="form-group d-inline-block mt-4 mb-1 bg-warning-light pad-10">
                  <span class="float-left font-weight-6 text-warning font-16">Detail Pemesanan</span>
                </div>
                <div class="form-group d-inline-block pl-2 pr-2 mt-4">
                  <span class="float-left">Nama Tour</span>
                  <span class="float-right font-weight-6 text-primary">{{ dataContent.nama }}</span>
                </div>
                <div class="form-group d-inline-block pl-2 pr-2 mt-4">
                  <span class="float-left">Harga/pax</span>
                  <span class="float-right font-weight-6 text-primary">IDR. {{ dataContent.harga | numeralFormat('0,0[.]00')  }}</span>
                </div>

                <div class="form-group d-inline-block pl-2 pr-2">
                  <span class="float-left">Tanggal Keberangkatan</span>
                  <span class="float-right font-weight-6 text-primary">{{ tourDate }}</span>
                </div>

                <div class="form-group d-inline-block pl-2 pr-2">
                  <span class="float-left">Jumlah Pax</span>
                  <span class="float-right font-weight-6 text-primary">
                    <span>{{ paxTour }}</span> PAX
                  </span>
                </div>

                <div class="form-group d-inline-block pl-2 pr-2">
                  <span class="float-left">Total DP</span>
                  <span class="float-right font-weight-6 text-primary">
                    <span>IDR.</span>
                   {{ paxCalculate(dataContent.dp_harga) | numeralFormat('0,0[.]00') }}
                  </span>
                </div>

                <div class="form-group d-inline-block bg-danger-light pt-3 pb-3 pl-2 pr-2">
                  <span class="float-left font-weight-6">Total Harga</span>
                  <span class="float-right font-weight-6 text-primary">
                    <span>IDR.</span>
                   {{ paxCalculate(dataContent.harga) | numeralFormat('0,0[.]00') }}
                  </span>
                </div>

                <div class="form-group d-inline-block pt-3 pb-3 pl-2 pr-2">
                  <span
                    class="d-block w-100 mb-6 cursor-pointer bg-primary text-white text-center pad-7 border-radius-5"
                    @click.prevent="backTo()"
                  >Kembali</span>

                  <span
                    class="d-block w-100 bg-warning cursor-pointer text-white text-center pad-7 border-radius-5"
                    @click.prevent="OpenPayment()"
                  >Bayar</span>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-Success"   hide-footer no-close-on-backdrop centered title>
      <div class="col-md-12 pb-6 text-center">
        <img width="150px" class="margin-auto pb-10 pt-4" src="@/assets/images/icon/check.svg" alt />
        <span class="text-primary font-weight-6 font-18">Pesanan Anda Sudah di Proses</span>
        <br />
        <span class="text-grey font-weight-5 font-16 d-block mb-20">Silahkan Pilih Pembayaran</span>
         <span  @click.prevent="PostData(2)"
          class="d-block width-50 bg-primary   pt-2 pb-2 font-weight-6 text-white border-radius-5 margin-auto mb-15  cursor-pointer"
        >Bayar DP</span>
        <span   @click.prevent="PostData(3)"
          class="d-block width-50 bg-warning pt-2 pb-2 font-weight-6 text-white border-radius-5 margin-auto mb-15   cursor-pointer"
        >Bayar Lunas</span>
      </div>
    </b-modal>
        <b-modal id="modal-Payment" scrollable    hide-footer no-close-on-backdrop centered  >
    <template v-slot:modal-title>
     <span class="d-block font-18 text-primary font-weight-7">Pilih Metode Pembayaran</span>
    </template>
        <payment :dataBinding='trasaction_inv'></payment>
 
    </b-modal>
  </div>
</template>
<script>
import Slick from "vue-slick";
import payment from "@/views/components/payment.vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import * as moment from "moment";
// import stripHtml from "string-strip-html";
export default {
  components: {
    Slick,
    flatPickr,
    payment
  },
  data() {
    return { 
      dataProduk:[],
       name_User: null, 
       LoginCheck:false,
        tourDate: null,
          date: new Date(),
        config: {
          mode: "single",
          minDate: "today",
          dateFormat: "D d-M-Y"
        },  
      paxTour: 1, 
      phone: null, 
      email: null, 
      response:false,
      resposive:true,
      trasaction_inv:null,
      dataContent:{}, 
      slideData:[],
      slickOptions: {
        slidesToShow: 1,
        infinite: false,
        autoplay: true,
        autoplaySpeed: 5000,
        dots: false, 
        arrows: false, 
      },
    
      slickOptionsThumb: {
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: ".slickDetailUp",
        dots: false,
        centerMode: false,
        focusOnSelect: true
      }
    };
  },
  mounted() {
    this.$store.commit("SET_BG", false);
    this.getData()
    if (window.screen.width <= 800) {
      this.responsive = false 
      // console.log('mobile');
      this.respon = true
      
    }else{
        this.responsive = true 
            this.respon = false
      //  console.log('desktop');
    }
    this.getListProduk()
     
  },
 watch: {
    slideData() {
      let currIndex = this.$refs.slick.currentSlide() 
      this.$refs.slick.destroy()
      this.$nextTick(() => {
        this.$refs.slick.create()
        this.$refs.slick.goTo(currIndex, true)
      })
      
    }
  },
  methods: {
    Validation() {   
      // this.Visa_pick = this.Visa_pick.text;
      if (this.$store.state.userdata !== null) {
      
        this.validator();
          this.LoginCheck = true;
      } else {
        this.$bvModal.show("modal-login");
      } 
    },
    validator(){
        this.$validator.validateAll().then(valid => {
        if (valid) { 
          this.LoginCheck = true;
        }
         this.LoginCheck = true;
        });
    },
    paxCalculate(neVal){
      if(this.paxTour  >  0 ){
      
        return neVal * this.paxTour 
      }else{
          return neVal
      }
      
    },
    OpenPayment(){
        this.$bvModal.show("modal-Success");
    },
    getData(){ 
      let param = new FormData();
      param.append("api_key", "2222");
      param.append("format", "");
      param.append("ws", "tour");
      param.append("type", "tour");
      param.append("product_id", "");  
      param.append("slug", this.$route.params.slug);  
      param.append("call", "list_product");

      this.axios
        .post("https://apitiket.realtravel.co.id/ws", param, {
          headers: new Headers({
            accept: "application/json",
            "Content-Type": "multipart/form-data, text/plain"
          })
        })
        .then(response => {
          let res = response.data.data[0];
          this.dataContent = res 
        this.slideData = res.images 
 
         
        })
        .catch(error => {
          error;
        });
     
    }, 
    PostData(newVal){
      let param = new FormData();
    
          param.append("api_key", "2222");
          param.append("format", "");
          param.append("ws", "tour"); 
          param.append("call", "book_product");
            param.append("partner_id", this.$store.state.userdata.partner_id);  
          param.append("product_id", this.$route.params.id);  
          param.append("name", this.name_User);
            param.append("phone", this.phone);
            param.append("pax", this.paxTour);
              param.append("date_depart", moment(this.tourDate).format("YYYY-MM-DD"))
              if(newVal == 2){
                param.append("amount",  this.dataContent.dp_harga);
              }else{
                param.append("amount",  this.dataContent.harga);
              }
            
    
        this.axios
          .post("https://apitiket.realtravel.co.id/ws", param, {
              headers: new Headers({
                accept: "application/json",
                "Content-Type": "multipart/form-data, text/plain"
              })
            })
          .then(response => {
            let res = response 
                this.$bvModal.hide("modal-Success");
            if(res.status == 200){ 
              this.trasaction_inv = res.data.data.transaction_detail.invoice_number
              setTimeout(() => {
                    this.$bvModal.show("modal-Payment");
              }, 500);
            }
          
            // console.log(res);
        });
     },    
     getListProduk() {
      let param = new FormData();
      param.append("api_key", "2222");
      param.append("format", "");
      param.append("ws", "tour");
      param.append("type", "tour");
      param.append("keyword", ''); 
      param.append("call", "list_product"); 
      this.axios
        .post("https://apitiket.realtravel.co.id/ws", param, {
          headers: new Headers({
            accept: "application/json",
            "Content-Type": "multipart/form-data, text/plain"
          })
        })
        .then(response => {
          let res = response.data;
          this.dataparse = res
          for (let i = 0; i < res.data.length; i++) {
            this.dataProduk.push(res.data[i])
          }
        })
        .catch(error => {
          error;
        });
     },
     chesen(val){
      //  console.log(val.images[0].file)
      return val.images[0].file
     },
     changePage(NewVal){
      this.$router.push('/tour/'+NewVal.slug)
      this.getData()
     },
     backTo() {
      this.LoginCheck = false;
     }
  },
};
</script>